<template>
  <Layout>
    <div class="right-align m-2">
      <b-button variant="primary" @click="AddGuide">
        <i class="fas fa-user-plus"></i><span class="pl-2">Guide</span>
      </b-button>
    </div>
    <div class="row">
      <div class="col-12">
        <div style="text-align: center; margin: 28px" v-if="dataLoading">
          <pulse-loader color="#e83e8c" />
        </div>
        <!-- User data -->
        <div class="row mt-2">
          <div
            class="col-sm-6 col-lg-4 col-xl-3"
            v-for="item in guideList"
            :key="item._id"
          >
            <guideCard :data="item" @dataAdded="getGuideList" />
          </div>
        </div>

        <div class="row" v-if="rows">
          <div class="col-sm-5">
            <div class="float-left">
              <label
                class="d-inline-flex align-items-center"
                style="min-width: 45px"
              >
                Show&nbsp;
                <b-form-select
                  v-model="perPage"
                  size="sm"
                  :options="pageOptions"
                  @change="perPageChange"
                ></b-form-select
                >&nbsp;entries
              </label>
            </div>
          </div>
          <div class="col-sm-7">
            <div class="float-right">
              <ul class="pagination pagination-rounded mb-4">
                <!-- pagination -->
                <b-pagination
                  v-model="currentPage"
                  :total-rows="rows"
                  :per-page="perPage"
                  @change="pageChange"
                ></b-pagination>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <guideModal
      :openModal="openModal"
      v-on:hideModal="modalClosed"
      :guideSelected="guideSelected"
      @dataAdded="getGuideList"
    />
  </Layout>
</template>
  
  
  <script>
import Layout from "@/views/layouts/main";
import appConfig from "@/app.config";
import { program } from "@/config/api/programs";
import guideModal from "@/views/pages/user/guide/guide-modal.vue";
import guideCard from "@/views/pages/user/guide/program-guide-card.vue";

export default {
  page: {
    title: "Program Guide",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, guideCard, guideModal },
  data() {
    return {
      guideSelected: false,
      openModal: false,
      title: "Program Guide",
      guideList: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      dataLoading: false,
      rows: 0,
      // fields: [
      //   { key: "fullname", sortable: false, label: "Name" },
      //   { key: "nomadic", sortable: false, label: "Nomad" },
      //   { key: "Contact", sortable: false },
      //   { key: "createdAt", sortable: false },
      //   { key: "updatedAt", sortable: false },
      // ],
    };
  },
  computed: {},
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
  },
  created() {
    this.getGuideList();
  },
  methods: {
    AddGuide() {
      this.openModal = true;
    },
    modalClosed() {
      this.openModal = false;
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    getGuideList() {
      this.dataLoading = true;
      this.guideList = [];
      const api = program.getGuideList;

      this.generateAPI(api)
        .then((res) => {
          console.log(res);
          console.log(res);
          this.guideList = res.data.data;
          this.rows = res.data.data.length;
          console.log(this.guideList);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.dataLoading = false;
          console.log("Loaded");
        });
    },
    pageChange(val) {
      this.currentPage = val;
      this.getGuideList();
    },
    perPageChange() {
      this.getGuideList();
    },
    selectUserAction(item) {
      this.selectUserID(item._id);
      this.$router.push({ name: "NormalUsersdetails" });
    },
  },
};
</script>
  