var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"size":"lg","title-class":"font-18","hide-footer":""},on:{"hidden":function($event){return _vm.$emit('hideModal')}},model:{value:(_vm.open),callback:function ($$v) {_vm.open=$$v},expression:"open"}},[_c('FormulateForm',{attrs:{"name":"guide"},on:{"submit":_vm.submitHandler}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('FormulateInput',{attrs:{"name":"name","type":"text","label":"Guide Name","placeholder":"Guide name here","validation":"required"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1),_c('div',{staticClass:"col-md-6"},[_c('FormulateInput',{attrs:{"name":"email","type":"email","label":"Email","placeholder":"Email here","validation":"^matches:/[^\\s@]+@[^\\s@]+\\.[^\\s@]+$/","validation-messages":{
            matches:
              'Please enter a valid email address (e.g., example@example.com)',
          }},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1),_c('div',{staticClass:"col-md-6"},[_c('FormulateInput',{attrs:{"name":"nationality","type":"text","label":"Nationality","placeholder":"Nationality here"},model:{value:(_vm.nationality),callback:function ($$v) {_vm.nationality=$$v},expression:"nationality"}})],1),_c('div',{staticClass:"col-md-6"},[_c('FormulateInput',{attrs:{"name":"whatsapp","type":"number","label":"Whatsapp Number","placeholder":"Whatsapp Number here"},model:{value:(_vm.whatsapp),callback:function ($$v) {_vm.whatsapp=$$v},expression:"whatsapp"}})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12 col-md-6"},[_c('FormulateInput',{attrs:{"name":"image","type":"image","uploader":_vm.uploadImage,"label":"Profile Image","help":"Select a png, jpg or svg to upload.","placeholder":"Profile Image"}})],1),(
          _vm.guideSelected == true &&
          _vm.selectedGuide != null &&
          _vm.selectedGuide.image
        )?_c('div',{staticClass:"col-sm-12 col-md-6",staticStyle:{"padding":"30px"}},[_c('img',{staticStyle:{"border":"1px solid #ddd","border-radius":"4px","padding":"3px","width":"80px"},attrs:{"src":this.selectedGuide.image,"width":"70","height":"70"}})]):_vm._e()]),_c('div',{staticClass:"right-align mt-4 mb-2"},[_c('FormulateInput',{attrs:{"type":"submit","label":_vm.guideSelected ? 'Update Guide' : 'Add Guide'}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }