<template>
  <b-modal
    size="lg"
    title-class="font-18"
    hide-footer
    v-model="open"
    @hidden="$emit('hideModal')"
  >
    <FormulateForm name="guide" @submit="submitHandler">
      <div class="row">
        <div class="col-md-6">
          <FormulateInput
            name="name"
            type="text"
            v-model="name"
            label="Guide Name"
            placeholder="Guide name here"
            validation="required"
          />
        </div>
        <div class="col-md-6">
          <FormulateInput
            name="email"
            type="email"
            v-model="email"
            label="Email"
            placeholder="Email here"
            validation="^matches:/[^\s@]+@[^\s@]+\.[^\s@]+$/"
            :validation-messages="{
              matches:
                'Please enter a valid email address (e.g., example@example.com)',
            }"
          />
        </div>

        <div class="col-md-6">
          <FormulateInput
            name="nationality"
            type="text"
            v-model="nationality"
            label="Nationality"
            placeholder="Nationality here"
          />
        </div>
        <div class="col-md-6">
          <FormulateInput
            name="whatsapp"
            type="number"
            v-model="whatsapp"
            label="Whatsapp Number"
            placeholder="Whatsapp Number here"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 col-md-6">
          <FormulateInput
            name="image"
            type="image"
            :uploader="uploadImage"
            label="Profile Image"
            help="Select a png, jpg or svg to upload."
            placeholder="Profile Image"
          />
        </div>
        <div
          v-if="
            guideSelected == true &&
            selectedGuide != null &&
            selectedGuide.image
          "
          class="col-sm-12 col-md-6"
          style="padding: 30px"
        >
          <img
            :src="this.selectedGuide.image"
            width="70"
            height="70"
            style="
              border: 1px solid #ddd;
              border-radius: 4px;
              padding: 3px;
              width: 80px;
            "
          />
        </div>
      </div>

      <div class="right-align mt-4 mb-2">
        <FormulateInput
          type="submit"
          :label="guideSelected ? 'Update Guide' : 'Add Guide'"
        />
      </div>
    </FormulateForm>
  </b-modal>
</template>
<script>
import { program } from "@/config/api/programs";
import compress from "@/utils/compress";

export default {
  data() {
    return {
      value: this.selectedGuide,
      open: this.openModal,
      loadingTribes: true,
      profileImage: null,
      imageLink: null,
      name: "",
      email: "",
      whatsapp: "",
      nationality: "",
    };
  },
  watch: {
    openModal(val) {
      this.open = val;
      if (this.guideSelected == true) {
        this.name = this.selectedGuide.name;
        this.email = this.selectedGuide.email;
        this.whatsapp = this.selectedGuide.whatsapp;
        this.nationality = this.selectedGuide.nationality;
      }
    },
  },

  props: ["openModal", "guideSelected", "selectedGuide"],

  methods: {
    validEmail(value) {
      // Regular expression to match email format
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(value);
    },
    async uploadImage(file, progress) {
      const form = new FormData();
      const compressed_file = await compress([file]);
      form.append("file", compressed_file);
      form.append("type", "admin");
      const api = program.uploadMultimedia;
      api.data = form;
      const result = await this.fileUploadAPI(api, progress).then((res) => {
        this.imageLink = res.data.link;
        return res.data;
      });
      return result;
    },
    submitHandler(data) {
      if (this.guideSelected) {
        const api = program.addGuide;
        (api.data = data),
          (data.image = this.imageLink),
          (data._id = this.selectedGuide._id),
          this.generateAPI(api)
            .then((res) => {
              if (res.data.data) {
                setTimeout(() => {
                  this.$bvToast.toast("Updated successfully!", {
                    title: "Guide",
                    variant: "success",
                    solid: true,
                  });
                }, 1000);

                this.$emit("hideModal");
                this.$emit("dataAdded");
                this.$formulate.reset("guide");
              } else {
                this.$bvToast.toast(res.data.message, {
                  title: "Guide",
                  variant: "danger",
                  solid: true,
                });
              }
            })
            .catch((err) => {
              this.$bvToast.toast(err.response.data.message, {
                title: "Guide",
                variant: "danger",
                solid: true,
              });
            });
      } else {
        const api = program.addGuide;
        (api.data = data),
          (data.image = this.imageLink),
          this.generateAPI(api)
            .then((res) => {
              if (res.data.data) {
                this.$bvToast.toast("Added successfully!", {
                  title: "Guide",
                  variant: "success",
                  solid: true,
                });
                this.$emit("hideModal");
                this.$emit("dataAdded");
                this.$formulate.reset("guide");
              } else {
                this.$bvToast.toast(res.data.message, {
                  title: "Guide",
                  variant: "danger",
                  solid: true,
                });
              }
            })
            .catch((err) => {
              this.$bvToast.toast(err.response.data.message, {
                title: "Guide",
                variant: "danger",
                solid: true,
              });
            });
      }
    },
  },
};
</script>

<style scoped>
.bank-details-vendor {
  border: 1px dashed;
  padding: 12px;
  border-radius: 12px;
  padding-bottom: 18px;
}

.multiselect__single {
  background: #f1f1f1 !important;
}
</style>
