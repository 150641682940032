<template>
    <b-card class="program-guide-card">
      <div>

<b-dropdown
right
toggle-class="arrow-none card-drop"
class="float-right"
variant="white"
style="margin-right: -12px"
>
<template v-slot:button-content>
  <i class="ri-settings-5-line"></i>
</template>
<b-dropdown-item  @click="updateGuide(data)"
>Edit</b-dropdown-item
>
<b-dropdown-item @click="deleteCard(data._id)">
  Delete</b-dropdown-item
  >
  
</b-dropdown>
</div>
      <b-card-body>

        <div class="details">
          <div style="padding: 10px;"> <img :src="data.image"  width="70" height="70" style=" border-radius: 5px;object-fit: cover;"></div>
          <div>
            <strong> Name: </strong>
            {{ data.name || "Not provided" }}
          </div>
          <div>
            <strong>  Email:</strong>
           
            {{ data.email || "Not provided" }}
          </div>
          <div>
            <strong> Nationality:</strong>
           {{ data.nationality || "Not provided" }}
          </div>
          <div>
            <strong> Whatsapp:</strong>
            {{ data.whatsapp || "Not provided" }} 
          </div>
        </div> 
        <guideModal
      :openModal="openModal"
      v-on:hideModal="modalClosed"
      :guideSelected="guideSelected"
      :selectedGuide="this.selectedGuide"
      @dataAdded="$emit('dataAdded')"
    />
      </b-card-body>
    </b-card>
    
  </template>
  
  <script>
    import guideModal from "@/views/pages/user/guide/guide-modal.vue";
  export default {
    components:{guideModal},
    props: ["data","dataAdded"],
    data() {
      return {
        open: this.openModal,
        openModal:false,
        selectedGuide:null,
        guideSelected:true,
      };
    },
    
    methods: {
      updateGuide(data){
        this.selectedGuide=data;
        this.openModal = true;
      },
      modalClosed() {
      this.openModal = false;
    },
      deleteCard(id){

        let api = {
                url: `programguide/${id}`,
                method:"DELETE",
                // data: null,
            }
            this.generateAPI(api)
          .then((res) => {
           
            if (res) {
              this.$bvToast.toast("Deleted successfully!", {
                title: "Guide",
                variant: "success",
                solid: true,
              });
              this.$emit("hideModal");
              this.$emit("dataAdded");
            } else {
              this.$bvToast.toast(res.data.message, {
                title: "Guide",
                variant: "danger",
                solid: true,
              });
            }
          })
          .catch((err) => {
            this.$bvToast.toast(err.response.data.message, {
              title: "Guide",
              variant: "danger",
              solid: true,
            });
          });
      }
    },
  };
  </script>
  
  <style scoped>
  .program-guide-card {
    text-align: center;
  }
  .details {
    display: block;
    flex-wrap: wrap;
    width: fit-content;
    margin: auto;
    justify-content:center;
  }
  .nomad-details strong {
    color: black;
  }
  .program-guide-card img {
    width: 68px;
    height: 68px;
    border-radius: 50%;
    display: block;
    margin: auto;
  }

  </style>